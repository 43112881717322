// Large devices (desktops, less than 1200px)
@media (min-width: 1199.98px) {
	.container {
		max-width: 1260px;
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {

	.select-wrapper {
		select {
			padding: 5px 60px 5px 10px;
		}
	}

	.products-flexbox {
		&.grid-flex {
			.product-service {
				width: 32%;
				padding: 0 10px;
			}
		}
	}

	nav.section-header {
		.header-management {
			flex-wrap: wrap;
			justify-content: center;
			padding-top: 20px;

			.management-logo {
				order: 1;
			}

			.management-search-engine {
				margin-top: 25px;
				order: 3;
				width: 100%;
			}

			.management-service {
				order: 2;
			}
		}

		.header-flexbox-wrapper {
			.header-flexbox {
				.flexbox-nav-wrapper {
					.flexbox-nav-inner {
						.flexbox-nav {
							text-align: center;
							padding: 0 10px;

							li {
								a {
									padding: 15px 0;
									font-size: 11px;
								}
							}
						}
					}
				}
			}
		}
	}

	section.section-latest {
		.tab-content {
			.tab-pane {
				.products-flexbox {
					.product-service {
						width: 32%;
					}
				}
			}
		}
	}

	section.section-cta {
		.cta-flexbox {
			.flexbox-service {
				.service-inner {
					padding: 20px;

					.inner-heading {
						p {
							font-size: 14px;
						}
					}
				}
			}
		}
	}

	section.kategoria-section-content {
		.content-flexbox {
			.flexbox-sidebar {
				width: 25%;
			}

			.flexbox-body {
				width: 72%;
			}
		}
	}

	section.section-footer {
		.footer-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-service {
				text-align: center;
				padding: 15px 0;
			}
		}

		.footer-partners {
			padding: 30px 0;
		}

		.footer-alert-wrapper {
			.alert {
				text-align: center;
			}
		}

		.footer-credits-wrapper {
			.footer-credits {
				flex-direction: column;
				text-align: center;

				p {
					font-size: 12px;
				}
			}
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
	nav.section-header {
		.header-management {
			flex-direction: column;

			.management-service {
				ul {
					li {
						&:first-of-type {
							display: none;
						}
					}
				}
			}
		}

		.header-top-wrapper {
			.header-top {
				.nav-button {
					display: flex;
					margin-right: -10px;
				}
			}
		}

		.header-flexbox-wrapper {
			.header-flexbox {
				justify-content: flex-end;

				.flexbox-search-engine {
					&.mobile {
						display: block;

						.button-search {
							border: none;
						}

						.input-wrapper {
							input {
								width: 300px;
							}
						}
					}

					&.desktop {
						display: none;
					}
				}

				.flexbox-nav-wrapper {
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					overflow-y: scroll;
					background: #fff;
					z-index: 555;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s;

					.nav-button-close {
						display: block;
					}

					.flexbox-nav-inner {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						min-height: 100%;
						padding: 50px;
						margin-right: 0;

						.management-logo.hide {
							display: none;

						}

						.flexbox-nav {
							flex-direction: column;
							background: transparent;

							li {
								margin-right: 0;

								a {
									font-size: 16px;
								}
							}
						}
					}

					&.active {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}

	section.section-welcome {
		.welcome-inner {
			h1 {
				font-size: 55px;
			}
		}
	}

	section.section-latest {
		.tab-content {
			.tab-pane {
				.products-flexbox {
					.product-service {
						width: 32%;
						padding: 0 20px;
					}
				}
			}
		}
	}

	section.section-cta {
		.cta-flexbox {
			grid-template-columns: 1fr;
		}
	}

	.products-flexbox {
		&.kategoria {
			.product-service {
				width: 30%;
			}
		}
	}

	section.kategoria-section-content {
		padding: 0 0 50px 0;

		.content-flexbox {
			flex-direction: column;

			.flexbox-sidebar {
				width: 100%;
				padding: 20px;

				.heading {
					text-align: center;
				}

				.category-nav-wrapper {
					position: fixed;
					top: 0;
					left: 0;
					height: 100%;
					width: 100%;
					overflow-y: scroll;
					background: #fff;
					z-index: 555;
					opacity: 0;
					visibility: hidden;
					transition: all 0.3s;

					.nav-button-close {
						display: block;
						margin-left: -15px;
					}

					.category-nav-inner {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-align: center;
						min-height: 100%;
						padding: 50px;

						.category-nav {
							display: flex;
							flex-direction: column;
							align-items: center;
							text-align: center;

							li {
								text-align: center;

								a {
									font-size: 17px;
									padding: 5px 0;
									color: #1a3c5e;
								}

								ul {
									padding-left: 0;

									li {
										a {
											color: @color1;
										}
									}
								}
							}
						}
					}

					&.active {
						opacity: 1;
						visibility: visible;
						margin-top: 0;
					}
				}

				.nav-button {
					display: flex;
					justify-content: center;
					padding: 15px 0;
				}
			}

			.flexbox-body {
				width: 100%;
				margin-top: 20px;

				.body-product {
					flex-direction: column;

					.product-gallery {
						width: 100%;
						display: block;
					}

					.product-info {
						width: 100%;
						padding-top: 30px;
						text-align: center;
					}
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-flexbox {
			flex-direction: column;
			align-items: center;

			.flexbox-heading {
				width: 100%;
				text-align: center
			}

			.flexbox-iframe {
				margin-top: 30px;
				width: 90%;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
	nav.section-header {
		.header-management {
			.management-service {
				.service-contact {
					p {
						display: none;
					}

					.contact-inner {
						flex-direction: column;

						svg,
						.divider {
							display: none;
						}

						a {
							margin: 5px 10px 0 0;
							font-size: 13px;
						}
					}
				}
			}
		}

		.header-top-wrapper {
			.header-top {
				ul {
					li {
						&:nth-of-type(3) {
							display: none;
						}
					}
				}
			}
		}
	}

	section.section-welcome {
		.welcome-inner {
			h1 {
				font-size: 46px;
				line-height: 50px;

				strong {
					font-size: 40px;
				}
			}

			.buttons-wrapper {
				flex-direction: column;

				.btn:not(:last-child) {
					margin: 0;

				}

				.btn-empty {
					margin-top: 15px;
				}
			}
		}
	}

	.products-flexbox {
		.product-service {
			border-right: none;
			padding: 0 10px;

			.service-quantity {
				button {
					height: 35px;
					width: 55px;
				}

				input {
					height: 35px;
					width: 60px;
					padding-left: 0;
				}
			}
		}

		&.grid-flex {
			.service-quantity {
				flex-direction: column;
				align-items: flex-start;

				.quantity-inner {
					margin-left: 0;
				}

				button {
					height: 35px;
					width: 55px;
				}

				input {
					height: 35px;
					width: 60px;
				}
			}
		}

		&.kategoria {
			.product-service {
				width: 47%;

				&:last-of-type {
					display: none;
				}
			}
		}

		&.grid-list {
			.product-service {
				text-align: left;
				padding: 20px 0;

				&:not(:first-child) {
					padding: 10px 0;
				}

				header {
					min-width: 22%;
				}

				.footer {
					width: 50%;
				}

				.name {
					font-size: 13px;
				}

				.price {
					display: flex;
					flex-direction: column;
					line-height: 20px;
				}

				.price-small {
					top: 70px;
				}

				.service-quantity {
					top: 125px;

					input {
						height: 30px;
					}

					button {
						width: 50px;
						height: 30px;
					}
				}

				.footer-quantity {
					margin-top: 8px;
				}
			}
		}
	}

	section.section-latest {
		.nav-tabs {
			.nav-item {
				font-size: 16px;
			}
		}

		.tab-content {
			.tab-pane {
				.products-flexbox {
					.product-service {
						width: 50%;
						padding: 0 17px;
					}
				}
			}
		}
	}

	section.section-cta {
		padding-top: 40px;
	}

	section.kategoria-section-content {
		.content-flexbox {
			.flexbox-body {
				.body-product {
					.product-info {
						.price {
							.quantity-wrapper {
								.wrapper-inner {
									justify-content: center;
									padding-top: 4px;
								}
							}

							.price-netto {
								justify-content: center;
							}
						}
					}
				}
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 400px) {

	.breadcrumb-wrapper {
		display: none;
	}

	.products-flexbox {
		&.wyszukiwanie {
			.product-service {
				width: 47%;
			}
		}
	}

	nav.section-header {
		.header-flexbox-wrapper {
			.header-flexbox {
				.flexbox-search-engine {
					&.mobile {
						.input-wrapper {
							input {
								width: 230px;
							}
						}
					}
				}
			}
		}
	}

	section.static-page-section-content {
		padding: 50px 0 70px 0;

		.content-inner {
			text-align: center;

			ul {
				text-align: left;
			}
		}
	}

	section.kategoria-section-content {
		padding: 20px 0 50px 0;

		.content-flexbox {
			.flexbox-body {
				.body-heading {
					padding: 10px 10px 10px 0;

					.heading-text {
						font-size: 11.5px;
					}

					.inner-wrapper {
						.heading-grid {
							margin-right: 10px;
						}

						.select-wrapper {
							width: 130px;

							select {
								padding: 5px 17px 5px 10px;
							}
						}
					}
				}
			}
		}
	}

	section.kontakt-section-content {
		.content-flexbox {
			.flexbox-iframe {
				height: 250px;
			}
		}
	}
}