@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap&subset=latin-ext');

body {
	min-height: 100vh;
	height: 100%;
	font-family: 'Lato', sans-serif;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	&.no-scroll {
		overflow: hidden;
		max-height: 100vh;
	}

	.btn {
		padding: 11px 20px;
		border: none;
		border-radius: 0;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 0.7px;
		text-transform: uppercase;
		cursor: pointer;
		transition: background 0.4s;

		&.btn-default {
			background: @color2;
			border: 1px solid @color2;
			color: #fff;

			&:hover {
				background: darken(@color2, 10%);
			}
		}

		&.btn-empty {
			background: none;
			color: #fff;
			border: 1px solid #fff;

			&:hover {
				background: #fff;
				color: @color2;
			}
		}
	}

	ul,
	ol,
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		padding: 0;
	}

	a {
		text-decoration: none !important;
	}
}

@color1: #afca05;
@color1: #a96d17;
@color2: #1a3c5e;

.select-wrapper {
	position: relative;

	select {
		cursor: pointer;
		padding: 5px 100px 5px 10px;
		border: none;
		background: #fff;
		height: 30px;
		-moz-appearance: none;
		-webkit-appearance: none;
		font-size: 13px;
		appearance: none;
		outline: none;
		display: block;
		color: #666;
		width: 100%;
	}

	&:after {
		cursor: pointer;
		content: '';
		height: 10px;
		width: 10px;
		background: url('../img/pictures/down-chevron.svg') center;
		background-size: cover;
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
}

.checkbox-wrapper {
	cursor: pointer;
	display: block;
	margin-bottom: 0;

	.radio-box {
		display: flex;
		align-items: center;
		font-size: 16px;
		text-transform: none;
		color: #414141;
		font-weight: 300;
		text-align: left;
		transition: all 0.3s;

		&:before {
			content: '';
			min-width: 16px;
			max-width: 16px;
			height: 16px;
			display: block;
			background: url('../img/pictures/checkbox.svg') no-repeat;
			margin-right: 10px;
			transition: all 0.3s;
		}
	}

	input {
		visibility: hidden;
		position: absolute;
		width: auto !important;
	}
}

.checkbox-wrapper>input:checked+.radio-box {
	font-weight: 700;

	&:before {
		background: url('../img/pictures/checkbox-checked.svg') no-repeat;
	}
}

.alert-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.2);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.4s;

	.alert-service {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100vh;
		width: 100%;
		transform: translateX(8px);
		padding: 50px 0;

		.service-inner {
			padding: 50px;
			background: #fff;
			width: 100%;
			box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			border-top: 5px solid #fff;
			position: relative;
			transform: scale(0.8);
			transition: all 0.5s;

			.inner-dismiss {
				top: 10px;
				right: 10px;
				position: absolute;
				border: 0;
				background: none;
				padding: 0;
				cursor: pointer;
				display: flex;
				padding: 5px;
				outline: none;

				img {
					height: 15px;
				}
			}

			&.success {
				border-top: 5px solid #78b042;
			}

			&.warning {
				border-top: 5px solid #d51a27;
			}

			&.info {
				border-top: 5px solid #1a88d5;
			}
		}
	}

	&.active {
		opacity: 1;
		visibility: visible;

		.alert-service {
			.service-inner {
				transform: none;
			}
		}
	}
}

.modal-service-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	background: rgba(0, 0, 0, 0.5);
	z-index: 555;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s;

	&.active {
		opacity: 1;
		visibility: visible;
	}

	.modal-service {
		padding: 50px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100%;

		.service-inner {
			background: #fff;
			width: 700px;
			max-width: 100%;
			padding: 50px 25px 25px 25px;
			text-align: center;
			position: relative;

			.service-button {
				position: absolute;
				top: 20px;
				right: 20px;
				border: none;
				background: none;
				padding: 15px;
				display: flex;
				justify-content: center;
				align-items: center;

				.button-bar {
					display: block;
					position: absolute;
					width: 20px;
					height: 2px;
					background: #222;

					&:nth-child(1) {
						transform: rotate(45deg);
					}

					&:nth-child(2) {
						transform: rotate(-45deg);
					}
				}
			}

			.service-content {
				.heading {
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					margin-bottom: 20px;
				}

				.btn {
					display: flex;
					justify-content: center;
					margin-top: 5px;
				}
			}

			.service-buttons {
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn-link {
					padding: 0;
					text-transform: uppercase;
					font-weight: 700;
					text-decoration: none;
				}
			}
		}
	}
}

.products-flexbox {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&.kategoria {
		.product-service {
			width: 25%;

			&:nth-child(4n) {
				border-right: none;
			}
		}
	}

	&.wyszukiwanie {
		.product-service {
			width: 20%;

			&:nth-child(5n) {
				border-right: none;
			}
		}
	}

	.product-service {
		margin-top: 50px;
		padding: 0 25px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		border-right: 1px solid #eee;
		text-align: center;

		.badge-wrapper {
			position: absolute;
			z-index: 111;
			top: 0;
			left: 25px;
			transform: rotate(90deg) translateY(-100%);
			transform-origin: left top;

			.badge {
				width: 78px;
				height: 18px;
				display: flex;
				align-items: center;
				padding-left: 6px;
				color: #fff;
				text-transform: uppercase;
				font-size: 9px;
				border-radius: 0;
				clip-path: polygon(0 0, 86% 0, 100% 100%, 0% 100%);

				&.promotion {
					background: #d52d00;
					display: none;
				}

				&.new {
					background: @color1;
					display: none;
				}

				&.bestseller {
					background: @color2;
					display: none;
				}
			}
		}

		.service-image-wrapper {
			width: 100%;
			padding-top: 100%;
			position: relative;
			border-radius: 4px;
			display: block;
			transition: all 0.4s;
			background: #fff;
			position: relative;

			.service-image {
				text-align: center;
				position: relative;
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				overflow: hidden;
				border-radius: 7px;

				img {
					width: 100%;
				}
			}

			&.active {
				&:after {
					content: '';
					height: 12px;
					width: 12px;
					border-radius: 50%;
					position: absolute;
					top: 0;
					left: 0;
					background: rgba(211, 14, 14, 1);
					box-shadow: 0px 0px 8px 0px rgba(211, 14, 14, 0.4);
				}
			}
		}

		.service-quantity {
			margin-top: 15px;
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				font-weight: 700;
				font-size: 12px;
			}

			.quantity-inner {
				display: flex;
				align-items: center;
				margin-left: 15px;
			}

			input {
				width: 55px;
				height: 25px;
				border-radius: 0;
				border: 1px solid @color1;
				font-size: 14px;
				outline: none;
				padding-left: 10px;
				transition: all 0.4s;
				text-align: center;
				-webkit-appearance: none;
				appearance: none;

				&.active {
					border: 1px solid rgba(211, 14, 14, 1);
				}
			}

			button {
				height: 25px;
				width: 25px;
				border-radius: 0;
				border: none;
				margin-left: 4px;
				background: @color1;
				color: #fff;
				cursor: pointer;
				transition: background 0.4s;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					height: 14px;
				}

				&:hover {
					background: darken(@color1, 10%);
				}
			}
		}

		.name {
			margin-top: 20px;
			color: #222;
			font-weight: 600;
			font-size: 14.5px;
			display: block;
			transition: all 0.4s;

			&.list-view {
				display: none;
			}
		}

		.price {
			margin-top: 10px;
			color: @color1;
			font-size: 18px;
			letter-spacing: -0.5px;
			font-weight: 600;

			small {
				font-size: 18px;
				font-weight: 600;
				color: @color2;
				margin-right: 5px;
				position: relative;
				display: none;

				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 1px;
					width: 100%;
					height: 1px;
					background: #555;
					transform: rotate(-10deg);
				}
			}

			span {
				font-weight: 400;
				color: @color2;
				font-size: 12px;
			}
		}

		.price-small {
			font-size: 12px;
			color: @color2;
		}

		.type {
			margin-top: 10px;
			font-size: 12px;
			color: #999;

			strong {
				color: @color1;
			}
		}

		.footer-quantity {
			margin-top: 15px;
			display: flex;
			justify-content: center;

			p {
				font-size: 11px;
				color: #333;

				&:nth-child(2) {
					margin-left: 7px;
					font-weight: 700;
					color: @color1;
				}
			}
		}

		.btn {
			margin-top: 17px;
		}

		&.promotion {
			.promotion {
				display: flex !important;
			}

			.price {
				color: #d52d00;

				small {
					display: inline-block;
				}
			}
		}

		&.new {
			.new {
				margin-top: 5px;
				display: flex !important;
			}
		}

		&.bestseller {
			.bestseller {
				margin-top: 5px;
				display: flex !important;
			}
		}

		&:hover {
			.service-image-wrapper {
				opacity: 0.7;
			}

			.name {
				color: @color1;
			}
		}

		&.service-hidden {
			margin: 0 !important;
			padding: 0 !important;
			border: none !important;
			visibility: hidden;
			opacity: 0;
		}
	}

	&.grid-list {
		.product-service {
			margin-top: 30px;
			width: 100% !important;
			flex-direction: row;
			justify-content: flex-start;
			padding-left: 0;
			border-right: none;
			text-align: left;

			header {
				min-width: 17%;
				margin-right: 15px;
			}

			.footer {
				width: 65%;
			}

			.badge-wrapper {
				right: auto;
				left: 0;
				top: 0;
			}

			.service-image-wrapper {
				&.active {
					&:after {
						left: auto;
						right: 0;
					}
				}
			}

			.price {
				position: absolute;
				right: 0;
				top: 25px;
				margin: 0;
			}

			.price-small {
				position: absolute;
				right: 0;
				top: 55px;
				margin: 0;
			}

			.service-quantity {
				position: absolute;
				right: 0;
				top: 90px;
				margin: 0;
			}

			.name {
				margin-top: 0;

				&.grid-view {
					display: none;
				}

				&.list-view {
					display: block;
				}
			}

			.footer-quantity {
				justify-content: flex-start;
			}

			&:not(:first-child) {
				padding-top: 30px;
				border-top: 1px solid #f5f5f5;
			}
		}
	}
}

.breadcrumb-wrapper {
	margin: 40px 0;

	.breadcrumb {
		border-radius: 0;
		padding: 0;
		background: none;
		margin-bottom: 0;

		.breadcrumb-item {
			font-size: 12px;
			font-weight: 500;

			a {
				color: @color1;
			}
		}
	}
}

.pagination-nav {
	margin-top: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;

	li {
		a {
			display: block;
			padding: 10px;
			color: #999;
			text-transform: uppercase;
		}

		&.active {
			a {
				font-weight: 700;
				color: @color1;
			}
		}
	}
}

nav.section-header {
	&.margin-bottom {
		margin-bottom: 70px;
	}

	.header-top-wrapper {
		background: #1a3c5e;
		padding: 13px 0;

		.header-top {
			display: flex;
			justify-content: space-between;
			align-items: center;

			p {
				font-size: 13px;
				letter-spacing: -0.5px;
				color: #fff;
			}

			ul {
				display: flex;
				align-items: center;

				li {
					a {
						font-size: 10px;
						color: #fff;
						text-transform: uppercase;
						font-weight: 300;
						display: flex;
						align-items: center;
						transition: all 0.4s;

						svg {
							height: 15px;
							width: 15px;
							margin-left: 6px;
							fill: @color1;
						}

						&:hover {
							color: @color1;
						}
					}

					&:not(:last-child) {
						margin-right: 20px;
					}
				}
			}

			.nav-button {
				padding: 10px;
				border: none;
				background: none;
				flex-direction: column;
				align-items: flex-end;
				justify-content: center;
				outline: none;
				display: none;

				.button-bar {
					height: 2px;
					width: 24px;
					background: #fff;
					border-radius: 10px;
					transition: all 0.4s;

					&:nth-child(2) {
						margin-top: 4px;
						width: 27px;
					}

					&:nth-child(3) {
						margin-top: 4px;
						width: 30px;
					}
				}

				&:hover,
				&:focus {
					.button-bar {
						width: 30px !important;
					}
				}
			}
		}
	}

	.header-management {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		.management-logo {
			img {
				height: 45px;
			}
		}

		.management-service {
			display: flex;
			align-items: center;

			.service-contact {
				.contact-inner {
					display: flex;
					align-items: center;

					svg {
						height: 20px;
						width: 20px;
						fill: @color1;
					}

					.divider {
						height: 15px;
						width: 1px;
						background: #bbb;
					}

					a {
						margin: 0 15px;
						font-size: 16px;
						font-weight: 900;
						color: @color2;
						transition: all 0.4s;

						&:hover {
							color: @color1;
						}
					}
				}

				p {
					margin: 5px 15px 0 0;
					color: #777;
					font-weight: 400;
					font-size: 12px;
					text-align: right;
				}
			}

			ul {
				display: flex;
				align-items: center;

				li {
					height: 90px;
					width: 90px;
					border-left: 1px solid #eee;
					display: flex;
					align-items: center;
					justify-content: center;

					a {
						position: relative;
						text-align: center;

						svg {
							height: 25px;
							width: 25px;
							fill: @color1;
						}

						p {
							margin-top: 5px;
							font-size: 10px;
							font-weight: 900;
							text-transform: uppercase;
							color: @color2;
							transition: all 0.4s;
						}

						&:hover {
							p {
								color: @color1;
							}
						}
					}

					&:last-child {
						border-right: 1px solid #eee;
					}
				}
			}
		}
	}

	.header-flexbox-wrapper {
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;

		.header-flexbox {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.flexbox-nav-wrapper {
				.nav-button-close {
					position: absolute;
					display: none;
					width: 100%;
					top: 20px;
					left: 0;

					.container {
						display: flex;
						justify-content: flex-end;

						&:before,
						&:after {
							content: none;
						}
					}

					button {
						outline: none;
						border: none;
						background: none;
						padding: 15px;
						display: flex;
						justify-content: center;
						align-items: center;

						.button-bar {
							display: block;
							position: absolute;
							width: 30px;
							height: 2px;
							background: #222;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								transform: rotate(-45deg);
							}
						}
					}
				}

				.flexbox-nav-inner {
					.flexbox-nav {
						display: flex;
						align-items: center;

						li {
							margin-right: 25px;

							a {
								color: @color2;
								font-weight: 900;
								font-size: 12px;
								text-transform: uppercase;
								display: block;
								padding: 18px 0;
								transition: all 0.4s;

								&:hover,
								&.active {
									color: @color1;
								}
							}

							&:last-child {
								margin-right: 0;
							}
						}
					}
				}
			}

			.flexbox-search-engine {
				height: 55px;
				position: relative;

				.button-search {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0;
					height: 100%;
					width: 90px;
					box-sizing: border-box;
					background: none;
					border: none;
					border-left: 1px solid #eee;
					border-right: 1px solid #eee;
					cursor: pointer;

					svg {
						height: 25px;
						width: 25px;
						fill: @color1;
					}

					&:hover,
					&.active {
						background: @color1;
						border-color: @color1;

						svg {
							fill: #fff;
						}
					}
				}

				.input-wrapper {
					right: 90px;
					top: 0;
					height: 100%;
					padding: 10px 10px 10px 80px;
					background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 12%, rgba(255, 255, 255, 1) 100%);
					position: absolute;
					opacity: 0;
					visibility: hidden;

					.button-close {
						position: absolute;
						padding: 0;
						height: 35px;
						width: 35px;
						box-sizing: border-box;
						background: none;
						border: none;
						outline: none;
						cursor: pointer;
						z-index: 111;

						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							height: 15px;
							width: 15px;
							fill: @color1;
							transform: translate(-50%, -50%);
						}

						&:hover {
							background: @color1;

							svg {
								fill: #fff;
							}
						}
					}

					input {
						height: 100%;
						width: 450px;
						background: #eee;
						padding: 5px 10px 5px 40px;
						border: 1px solid transparent;
						font-size: 14px;
						color: #666;
						outline: none;
						opacity: 0;

						&:focus,
						&.active {
							border: 1px solid @color1;
						}
					}

					&.active {
						visibility: visible;
						opacity: 1;

						input {
							opacity: 1;
						}
					}
				}

				&.mobile {
					display: none;
				}
			}

		}
	}
}

section.section-welcome {
	.welcome-inner {
		height: 400px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h1 {
			font-weight: 300;
			color: #fff;
			font-size: 60.5px;
			text-align: center;

			strong {
				font-weight: 700;
				font-size: 56.5px;
			}
		}

		.buttons-wrapper {
			margin-top: 30px;
			display: flex;
			align-items: center;

			.btn {
				&:not(:last-child) {
					margin-right: 30px;
				}
			}
		}
	}
}

section.section-latest {
	padding-top: 75px;

	.nav-tabs {
		margin-top: -10px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;

		.nav-item {
			padding: 10px;
			margin: 0;
			border: none;
			font-size: 18px;
			text-transform: uppercase;
			color: @color2;
			transition: all 0.4s;

			&.active {
				color: @color1;
			}
		}
	}

	.tab-content {
		.tab-pane {
			.products-flexbox {
				margin-top: 10px;

				.product-service {
					width: 20%;

					&:nth-child(5),
					&:nth-child(10) {
						border-right: none;
					}
				}
			}
		}
	}
}

section.section-cta {
	padding-top: 70px;

	.cta-flexbox {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		.flexbox-service {
			position: relative;

			.service-cover {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: @color2;
				opacity: 0.75;
			}

			.service-inner {
				position: relative;
				padding: 50px;
				text-align: center;

				.inner-heading {
					display: flex;
					justify-content: center;
					align-items: center;

					svg {
						height: 40px;
						width: 40px;
						fill: @color1;
						margin-right: 10px;
					}

					p {
						font-size: 15px;
						color: #fff;
						font-weight: 600;
						text-transform: uppercase;
					}
				}

				.inner-description {
					margin-top: 20px;

					p {
						color: #fff;
						font-weight: 300;
						font-size: 14px;
					}
				}
			}

			&:not(:last-child) {
				border-right: 1px solid #86a6c6;
			}
		}
	}
}

section.kategoria-section-content {
	.content-flexbox {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.flexbox-sidebar {
			width: 20%;
			padding: 32px 20px 20px 20px;
			background: @color2;
			position: relative;

			.heading {
				margin-bottom: 20px;

				p {
					font-size: 18px;
					text-transform: uppercase;
					color: #fff;
				}
			}

			.nav-button {
				outline: none;
				padding: 10px;
				width: 100%;
				border: none;
				font-size: 11px;
				color: #fff;
				text-transform: uppercase;
				background: @color1;
				color: #fff;
				transition: all 0.3s;
				display: none;
				cursor: pointer;
				position: relative;

				&:hover {
					background: @color1;
				}
			}

			.category-nav-wrapper {
				position: relative;

				.nav-button-close {
					position: absolute;
					display: none;
					width: 100%;
					top: 20px;
					left: 0;

					.container {
						display: flex;
						justify-content: flex-end;

						&:before,
						&:after {
							content: none;
						}
					}

					button {
						outline: none;
						border: none;
						background: none;
						padding: 15px;
						display: flex;
						justify-content: center;
						align-items: center;
						cursor: pointer;

						.button-bar {
							display: block;
							position: absolute;
							width: 30px;
							height: 2px;
							background: #222;

							&:nth-child(1) {
								transform: rotate(45deg);
							}

							&:nth-child(2) {
								transform: rotate(-45deg);
							}
						}
					}
				}

				.category-nav-inner {
					.category-nav {
						li {
							list-style: none;

							a {
								color: #fff;
								opacity: 0.8;
								padding: 5px 0;
								font-size: 16px;
								transition: all 0.4s;
								display: block;

								&:hover {
									color: @color1;
									padding-left: 5px;
								}
							}

							ul {
								padding-left: 20px;

								li {
									a {
										padding: 5px 0;
										display: block;
										font-size: 14px;
										color: #fff;
									}
								}
							}

							&.active {
								>a {
									opacity: 1;
									font-weight: 700;
									color: @color1;
									padding-left: 0;
								}
							}

							&:last-child {
								margin-bottom: 0;
							}
						}
					}

					.filter-nav {
						margin-top: 50px;

						.heading {
							&:not(:first-of-type) {
								margin-top: 50px;
							}
						}

						.checkbox-wrapper {
							.radio-box {
								color: #fff;
							}
						}

						.range-slider {
							width: 100%;
							margin: auto;
							text-align: center;
							position: relative;

							.slider-wrapper {
								display: flex;
								align-items: center;
								justify-content: space-between;
								background: #f5f5f5;
								padding: 10px;

								input[type=number] {
									border: none;
									margin: 0 5px;
									outline: none;
									text-align: center;
									background: @color2;
									color: #fff;
									font-size: 14px;
									height: 30px;
									width: 100%;
									-moz-appearance: textfield;
								}

								input[type=number]::-webkit-outer-spin-button,
								input[type=number]::-webkit-inner-spin-button {
									-webkit-appearance: none;
								}

								input[type=number]:invalid,
								input[type=number]:out-of-range {
									border: 1px solid #ff6347;
								}

								span {
									display: block;
									min-width: 12px;
								}

								p {
									font-size: 13px;
								}
							}

							svg,
							input[type=range] {
								position: absolute;
								left: 0;
								bottom: -40px;
							}

							input[type=range] {
								-webkit-appearance: none;
								width: 100%;
							}

							input[type=range]:focus {
								outline: none;
							}

							input[type=range]:focus::-webkit-slider-runnable-track {
								background: #cba42d;
							}

							input[type=range]:focus::-ms-fill-lower {
								background: #cba42d;
							}

							input[type=range]:focus::-ms-fill-upper {
								background: #cba42d;
							}

							input[type=range]::-webkit-slider-runnable-track {
								width: 100%;
								height: 3px;
								cursor: pointer;
								animate: 0.2s;
								background: #cba42d;
								border-radius: 1px;
								box-shadow: none;
								border: 0;
							}

							input[type=range]::-webkit-slider-thumb {
								z-index: 2;
								position: relative;
								box-shadow: 0px 0px 0px #000;
								border: 1px solid #cba42d;
								height: 14px;
								width: 14px;
								border-radius: 25px;
								background: #ffeaa1;
								cursor: pointer;
								-webkit-appearance: none;
								margin-top: -5px;
							}

							input[type=range]::-moz-range-track {
								width: 100%;
								height: 5px;
								cursor: pointer;
								animate: 0.2s;
								background: #cba42d;
								border-radius: 1px;
								box-shadow: none;
								border: 0;
							}

							input[type=range]::-moz-range-thumb {
								z-index: 2;
								position: relative;
								box-shadow: 0px 0px 0px #000;
								border: 1px solid #cba42d;
								height: 18px;
								width: 18px;
								border-radius: 25px;
								background: #ffeaa1;
								cursor: pointer;
							}

							input[type=range]::-ms-track {
								width: 100%;
								height: 5px;
								cursor: pointer;
								animate: 0.2s;
								background: transparent;
								border-color: transparent;
								color: transparent;
							}

							input[type=range]::-ms-fill-lower,
							input[type=range]::-ms-fill-upper {
								background: #cba42d;
								border-radius: 1px;
								box-shadow: none;
								border: 0;
							}

							input[type=range]::-ms-thumb {
								z-index: 2;
								position: relative;
								box-shadow: 0px 0px 0px #000;
								border: 1px solid #cba42d;
								height: 18px;
								width: 18px;
								border-radius: 25px;
								background: #ffeaa1;
								cursor: pointer;
							}
						}

						.buttons-wrapper {
							margin-top: 90px;
							display: flex;
							align-items: center;
							justify-content: space-between;

							.btn-clear {
								color: #fff;
								border: none;
								font-size: 13px;
								background: none;
								cursor: pointer;
								padding: 0;
							}

							.btn-filter {
								padding: 0;
								border: none;
								background: @color1;
								font-size: 13px;
								border-radius: 0;
								color: #fff;
								padding: 3px 10px;
								cursor: pointer;
								transition: all 0.3s;
							}
						}
					}
				}
			}
		}

		.flexbox-body {
			width: 75%;

			.body-heading {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 20px;
				padding: 10px 10px 10px 20px;
				background: #f5f5f5;

				.heading-text {
					font-size: 13px;
					color: #777;
					position: relative;
					text-align: center;
				}

				.inner-wrapper {
					display: flex;
					align-items: center;

					.heading-grid {
						margin-right: 30px;
						display: flex;

						a,
						button {
							margin-right: 7px;
							padding: 0;
							border: none;
							background: #fff;
							outline: none;
							cursor: pointer;
							display: flex;
							align-items: center;
							justify-content: center;
							height: 30px;
							width: 30px;
							transition: all 0.4s;

							img {
								width: 15px;
								opacity: 0.25;
								transition: all 0.4s;
							}

							&:last-child {
								margin-right: 0;
							}

							&:hover {
								img {
									opacity: 1;
								}
							}

							&.active {
								border: 1px solid @color1;

								img {
									opacity: 1;
								}
							}
						}
					}
				}
			}


			.heading {
				margin-top: 60px;
				text-align: center;

				p {
					font-size: 18px;
					text-transform: uppercase;
					color: @color2;
				}
			}

			.body-cta {
				margin-top: 40px;
				display: block;
				position: relative;
				transition: border-radius 0.4s;
				overflow: hidden;

				img {
					width: 100%;
				}

				.cta-inner {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: flex-end;
					text-align: right;
					padding: 30px;

					p {
						font-weight: 300;
						font-size: 24px;
						color: #fff;

						strong {
							font-size: 31px;
							font-weight: 700;
						}
					}
				}

				&:hover {
					border-radius: 10px;
				}
			}

			.btn-fixed {
				position: fixed;
				bottom: 25px;
				right: 25px;
				z-index: 222;
				opacity: 0;
				visibility: hidden;
				transition: all 0.4s;
				box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.3);

				&.active {
					opacity: 1;
					visibility: visible;
				}
			}

			.body-product {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;

				.product-gallery {
					width: 38%;
					display: none;

					img {
						width: 100%;
					}

					.lSAction {
						.lSPrev {
							width: 25px;
							height: 25px;
							background: url('../library/lightslider-master/dist/img/prev.svg') center;
							background-size: cover;
							margin-top: 0px;
							transform: translateY(-50%);
							left: 1px;
						}

						.lSNext {
							width: 25px;
							height: 25px;
							background: url('../library/lightslider-master/dist/img/prev.svg') center;
							background-size: cover;
							margin-top: 0px;
							transform: translateY(-50%) rotate(180deg);
							right: 1px;
						}
					}

					.lSGallery {
						margin-top: 23px !important;

						li {
							border: none;
							opacity: 0.6;
							transition: all 0.3s;

							&.active,
							&:hover {
								opacity: 1;
							}
						}
					}
				}

				.product-info {
					// width: 55%;
					width: 100%;

					h2 {
						font-size: 36px;
						font-weight: 400;
						font-family: 'Montserrat', sans-serif;
					}

					.code {
						margin-top: 20px;
						font-size: 16px;
						font-weight: 300;

						strong {
							font-weight: 700;
							color: @color1;
						}
					}

					.description {
						margin-top: 20px;

						p {
							font-size: 14px;
						}
					}

					.features {
						margin-top: 20px;

						>* {
							&:not(:first-child) {
								margin-top: 10px;
							}
						}

						p {
							font-size: 16px;
							font-weight: 300;

							strong {
								font-weight: 700;
								color: #999;
							}
						}

						.select-wrapper-flexbox {
							label {
								font-size: 16px;
								font-weight: 300;
							}

							.select-wrapper {
								position: relative;

								select {
									cursor: pointer;
									padding: 10px 100px 10px 10px;
									border: none;
									border: 1px solid #eee;
									-moz-appearance: none;
									-webkit-appearance: none;
									appearance: none;
									outline: none;
									display: block;
									color: #666;
									width: 100%;
									height: auto;
								}

								&:after {
									cursor: pointer;
									content: '';
									height: 10px;
									width: 10px;
									background: url('../img/pictures/down-chevron.svg') center;
									background-size: cover;
									position: absolute;
									right: 10px;
									top: 50%;
									transform: translateY(-50%);
								}
							}
						}
					}

					.price {
						margin-top: 20px;
						padding: 23px 20px 20px 20px;
						background: #f5f5f5;

						.price-netto {
							color: @color1;
							font-size: 40px;
							font-weight: 500;
							line-height: 30px;
							display: flex;
							align-items: flex-end;

							span {
								font-weight: 400;
								color: #999;
								font-size: 12px;
								line-height: 15px;
								padding-left: 10px;
							}
						}

						.price-brutto {
							margin-top: 10px;
							font-weight: 400;
							color: #999;
							font-size: 18px;
							line-height: 18px;
							padding-left: 3px;
						}

						.quantity-wrapper {
							margin-top: 20px;

							.wrapper-inner {
								display: flex;

								.input-number {
									width: 100%;
									padding: 0 12px;
									text-align: center;
									outline: none;
									background: none;
									border: none;
									border: 1px solid #999;
								}

								.input-number,
								.input-number-decrement,
								.input-number-increment {
									height: 35px;
									user-select: none;
								}

								.input-number-decrement,
								.input-number-increment {
									min-width: 35px;
									background: @color1;
									color: #fff;
									text-align: center;
									font-weight: 700;
									display: flex;
									align-items: center;
									justify-content: center;
									transition: background 0.4s;
									cursor: pointer;
									border: 1px solid #999;
									outline: none;

									&:active {
										background: @color2;
									}
								}

								input[type=number]::-webkit-inner-spin-button,
								input[type=number]::-webkit-outer-spin-button {
									-moz-appearance: none;
									-webkit-appearance: none;
									appearance: none;
									margin: 0;
								}

								input[type=number] {
									-moz-appearance: textfield;
									margin: 0 10px;
								}
							}

							.btn {
								margin-top: 20px;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

section.static-page-section-content {
	padding: 0 0 70px 0;

	.content-heading {
		text-align: center;

		h2 {
			font-size: 36px;
			font-weight: 400;
			font-family: 'Montserrat', sans-serif;
		}
	}

	.content-inner {
		margin-top: 40px;

		>* {
			&:not(:first-child) {
				margin-top: 20px;
			}
		}

		p,
		li {
			font-size: 15px;
			line-height: 26px;
			color: #444;
		}

		ul,
		ol {
			padding-left: 17px;
		}
	}
}

section.kontakt-section-content {
	padding: 30px 0;
	position: relative;

	.content-flexbox {
		display: flex;
		justify-content: space-between;

		.flexbox-heading {
			width: 35%;

			h2 {
				color: #444;
				font-size: 22px;
				font-weight: 400;
			}

			h3 {
				margin-top: 8px;
				color: @color1;
				font-size: 45px;
				font-weight: 800;
			}

			address {
				margin-top: 20px;
				color: #666;
				font-size: 18px;
				font-weight: 400;

				small {
					margin-top: 20px;
					display: block;
					color: #999;
					margin-bottom: 7px;
				}

				a {
					font-weight: 700;
					color: @color1;
				}
			}
		}

		.flexbox-iframe {
			width: 57%;
			height: 400px;
			position: relative;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				border: none;
			}
		}
	}
}

section.section-footer {
	background: @color2;
	position: relative;

	.footer-partners {
		background: #fff;
		padding: 50px 0 40px 0;

		.owl-carousel {
			.owl-controls {
				display: none;
			}
		}
	}

	.footer-flexbox {
		display: flex;
		justify-content: space-between;
		position: relative;

		.flexbox-service {
			padding: 50px 0;

			&.service-about {
				.service-logo {
					img {
						height: 45px;
					}
				}

				.service-description {
					margin-top: 20px;

					p {
						color: #fff;
						font-weight: 300;
						font-size: 14px;
					}
				}
			}

			ul {
				li {
					margin-top: 5px;

					&.heading {
						margin-top: 0;
						padding-bottom: 10px;
						font-size: 14px;
						color: #fff;
						text-transform: uppercase;
					}

					a {
						font-size: 13px;
						font-weight: 300;
						color: #fff;
						transition: all 0.3s;

						b {
							font-size: 16px;
						}

						span {
							font-size: 10px;
						}

						&:hover {
							color: @color1;
						}
					}

					p {
						font-size: 12px;
						color: #444;

						b {
							font-weight: 400;
							font-size: 14px;
						}

						span {
							font-size: 10px;
						}
					}
				}
			}

			.list-social {
				display: flex;
				align-items: center;
				justify-content: flex-end;

				li {
					margin-right: 20px;

					a {
						img {
							height: 25px;
						}
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.divider {
			width: 1px;
			background: rgba(255, 255, 255, 0.2);
			;
		}
	}

	.footer-alert-wrapper {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		padding: 40px 0;

		.alert {
			position: relative;
			background: none;
			border: none;
			border-radius: 0;
			margin: 0;
			padding: 0;
			background: none;

			p {
				font-size: 14px;
				color: #fff;
				font-weight: 300;

				a {
					color: @color1;
					cursor: pointer;
				}
			}
		}
	}

	.footer-credits-wrapper {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		padding: 25px 0;

		.footer-credits {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;

			p {
				font-size: 14px;
				color: #fff;
				font-weight: 300;

				a {
					color: @color1;
				}
			}

			.footer-payment {
				display: flex;
				align-items: center;

				p {
					margin-right: 15px;
				}

				img {
					margin-right: 10px;
					height: 19px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}
}