@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: local('Lato Light'), local('Lato-Light'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwWw.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPHA.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwaPHA.ttf) format('truetype');
}
body {
  min-height: 100vh;
  height: 100%;
  font-family: 'Lato', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
body.no-scroll {
  overflow: hidden;
  max-height: 100vh;
}
body .btn {
  padding: 11px 20px;
  border: none;
  border-radius: 0;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.7px;
  text-transform: uppercase;
  cursor: pointer;
  transition: background 0.4s;
}
body .btn.btn-default {
  background: #1a3c5e;
  border: 1px solid #1a3c5e;
  color: #fff;
}
body .btn.btn-default:hover {
  background: #0f2236;
}
body .btn.btn-empty {
  background: none;
  color: #fff;
  border: 1px solid #fff;
}
body .btn.btn-empty:hover {
  background: #fff;
  color: #1a3c5e;
}
body ul,
body ol,
body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  margin: 0;
  padding: 0;
}
body a {
  text-decoration: none !important;
}
.select-wrapper {
  position: relative;
}
.select-wrapper select {
  cursor: pointer;
  padding: 5px 100px 5px 10px;
  border: none;
  background: #fff;
  height: 30px;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-size: 13px;
  appearance: none;
  outline: none;
  display: block;
  color: #666;
  width: 100%;
}
.select-wrapper:after {
  cursor: pointer;
  content: '';
  height: 10px;
  width: 10px;
  background: url('../img/pictures/down-chevron.svg') center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.checkbox-wrapper {
  cursor: pointer;
  display: block;
  margin-bottom: 0;
}
.checkbox-wrapper .radio-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: none;
  color: #414141;
  font-weight: 300;
  text-align: left;
  transition: all 0.3s;
}
.checkbox-wrapper .radio-box:before {
  content: '';
  min-width: 16px;
  max-width: 16px;
  height: 16px;
  display: block;
  background: url('../img/pictures/checkbox.svg') no-repeat;
  margin-right: 10px;
  transition: all 0.3s;
}
.checkbox-wrapper input {
  visibility: hidden;
  position: absolute;
  width: auto !important;
}
.checkbox-wrapper > input:checked + .radio-box {
  font-weight: 700;
}
.checkbox-wrapper > input:checked + .radio-box:before {
  background: url('../img/pictures/checkbox-checked.svg') no-repeat;
}
.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
}
.alert-wrapper .alert-service {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  transform: translateX(8px);
  padding: 50px 0;
}
.alert-wrapper .alert-service .service-inner {
  padding: 50px;
  background: #fff;
  width: 100%;
  box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
  text-align: center;
  border-top: 5px solid #fff;
  position: relative;
  transform: scale(0.8);
  transition: all 0.5s;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss {
  top: 10px;
  right: 10px;
  position: absolute;
  border: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  padding: 5px;
  outline: none;
}
.alert-wrapper .alert-service .service-inner .inner-dismiss img {
  height: 15px;
}
.alert-wrapper .alert-service .service-inner.success {
  border-top: 5px solid #78b042;
}
.alert-wrapper .alert-service .service-inner.warning {
  border-top: 5px solid #d51a27;
}
.alert-wrapper .alert-service .service-inner.info {
  border-top: 5px solid #1a88d5;
}
.alert-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.alert-wrapper.active .alert-service .service-inner {
  transform: none;
}
.modal-service-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}
.modal-service-wrapper.active {
  opacity: 1;
  visibility: visible;
}
.modal-service-wrapper .modal-service {
  padding: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
}
.modal-service-wrapper .modal-service .service-inner {
  background: #fff;
  width: 700px;
  max-width: 100%;
  padding: 50px 25px 25px 25px;
  text-align: center;
  position: relative;
}
.modal-service-wrapper .modal-service .service-inner .service-button {
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar {
  display: block;
  position: absolute;
  width: 20px;
  height: 2px;
  background: #222;
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
.modal-service-wrapper .modal-service .service-inner .service-content .heading {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.modal-service-wrapper .modal-service .service-inner .service-content .btn {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-service-wrapper .modal-service .service-inner .service-buttons .btn-link {
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}
.products-flexbox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.products-flexbox.kategoria .product-service {
  width: 25%;
}
.products-flexbox.kategoria .product-service:nth-child(4n) {
  border-right: none;
}
.products-flexbox.wyszukiwanie .product-service {
  width: 20%;
}
.products-flexbox.wyszukiwanie .product-service:nth-child(5n) {
  border-right: none;
}
.products-flexbox .product-service {
  margin-top: 50px;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-right: 1px solid #eee;
  text-align: center;
}
.products-flexbox .product-service .badge-wrapper {
  position: absolute;
  z-index: 111;
  top: 0;
  left: 25px;
  transform: rotate(90deg) translateY(-100%);
  transform-origin: left top;
}
.products-flexbox .product-service .badge-wrapper .badge {
  width: 78px;
  height: 18px;
  display: flex;
  align-items: center;
  padding-left: 6px;
  color: #fff;
  text-transform: uppercase;
  font-size: 9px;
  border-radius: 0;
  clip-path: polygon(0 0, 86% 0, 100% 100%, 0% 100%);
}
.products-flexbox .product-service .badge-wrapper .badge.promotion {
  background: #d52d00;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.new {
  background: #a96d17;
  display: none;
}
.products-flexbox .product-service .badge-wrapper .badge.bestseller {
  background: #1a3c5e;
  display: none;
}
.products-flexbox .product-service .service-image-wrapper {
  width: 100%;
  padding-top: 100%;
  border-radius: 4px;
  display: block;
  transition: all 0.4s;
  background: #fff;
  position: relative;
}
.products-flexbox .product-service .service-image-wrapper .service-image {
  text-align: center;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 7px;
}
.products-flexbox .product-service .service-image-wrapper .service-image img {
  width: 100%;
}
.products-flexbox .product-service .service-image-wrapper.active:after {
  content: '';
  height: 12px;
  width: 12px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background: #d30e0e;
  box-shadow: 0px 0px 8px 0px rgba(211, 14, 14, 0.4);
}
.products-flexbox .product-service .service-quantity {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-flexbox .product-service .service-quantity p {
  font-weight: 700;
  font-size: 12px;
}
.products-flexbox .product-service .service-quantity .quantity-inner {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.products-flexbox .product-service .service-quantity input {
  width: 55px;
  height: 25px;
  border-radius: 0;
  border: 1px solid #a96d17;
  font-size: 14px;
  outline: none;
  padding-left: 10px;
  transition: all 0.4s;
  text-align: center;
  -webkit-appearance: none;
  appearance: none;
}
.products-flexbox .product-service .service-quantity input.active {
  border: 1px solid #d30e0e;
}
.products-flexbox .product-service .service-quantity button {
  height: 25px;
  width: 25px;
  border-radius: 0;
  border: none;
  margin-left: 4px;
  background: #a96d17;
  color: #fff;
  cursor: pointer;
  transition: background 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-flexbox .product-service .service-quantity button img {
  height: 14px;
}
.products-flexbox .product-service .service-quantity button:hover {
  background: #7c5011;
}
.products-flexbox .product-service .name {
  margin-top: 20px;
  color: #222;
  font-weight: 600;
  font-size: 14.5px;
  display: block;
  transition: all 0.4s;
}
.products-flexbox .product-service .name.list-view {
  display: none;
}
.products-flexbox .product-service .price {
  margin-top: 10px;
  color: #a96d17;
  font-size: 18px;
  letter-spacing: -0.5px;
  font-weight: 600;
}
.products-flexbox .product-service .price small {
  font-size: 18px;
  font-weight: 600;
  color: #1a3c5e;
  margin-right: 5px;
  position: relative;
  display: none;
}
.products-flexbox .product-service .price small:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 1px;
  width: 100%;
  height: 1px;
  background: #555;
  transform: rotate(-10deg);
}
.products-flexbox .product-service .price span {
  font-weight: 400;
  color: #1a3c5e;
  font-size: 12px;
}
.products-flexbox .product-service .price-small {
  font-size: 12px;
  color: #1a3c5e;
}
.products-flexbox .product-service .type {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
}
.products-flexbox .product-service .type strong {
  color: #a96d17;
}
.products-flexbox .product-service .footer-quantity {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.products-flexbox .product-service .footer-quantity p {
  font-size: 11px;
  color: #333;
}
.products-flexbox .product-service .footer-quantity p:nth-child(2) {
  margin-left: 7px;
  font-weight: 700;
  color: #a96d17;
}
.products-flexbox .product-service .btn {
  margin-top: 17px;
}
.products-flexbox .product-service.promotion .promotion {
  display: flex !important;
}
.products-flexbox .product-service.promotion .price {
  color: #d52d00;
}
.products-flexbox .product-service.promotion .price small {
  display: inline-block;
}
.products-flexbox .product-service.new .new {
  margin-top: 5px;
  display: flex !important;
}
.products-flexbox .product-service.bestseller .bestseller {
  margin-top: 5px;
  display: flex !important;
}
.products-flexbox .product-service:hover .service-image-wrapper {
  opacity: 0.7;
}
.products-flexbox .product-service:hover .name {
  color: #a96d17;
}
.products-flexbox .product-service.service-hidden {
  margin: 0 !important;
  padding: 0 !important;
  border: none !important;
  visibility: hidden;
  opacity: 0;
}
.products-flexbox.grid-list .product-service {
  margin-top: 30px;
  width: 100% !important;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 0;
  border-right: none;
  text-align: left;
}
.products-flexbox.grid-list .product-service header {
  min-width: 17%;
  margin-right: 15px;
}
.products-flexbox.grid-list .product-service .footer {
  width: 65%;
}
.products-flexbox.grid-list .product-service .badge-wrapper {
  right: auto;
  left: 0;
  top: 0;
}
.products-flexbox.grid-list .product-service .service-image-wrapper.active:after {
  left: auto;
  right: 0;
}
.products-flexbox.grid-list .product-service .price {
  position: absolute;
  right: 0;
  top: 25px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .price-small {
  position: absolute;
  right: 0;
  top: 55px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .service-quantity {
  position: absolute;
  right: 0;
  top: 90px;
  margin: 0;
}
.products-flexbox.grid-list .product-service .name {
  margin-top: 0;
}
.products-flexbox.grid-list .product-service .name.grid-view {
  display: none;
}
.products-flexbox.grid-list .product-service .name.list-view {
  display: block;
}
.products-flexbox.grid-list .product-service .footer-quantity {
  justify-content: flex-start;
}
.products-flexbox.grid-list .product-service:not(:first-child) {
  padding-top: 30px;
  border-top: 1px solid #f5f5f5;
}
.breadcrumb-wrapper {
  margin: 40px 0;
}
.breadcrumb-wrapper .breadcrumb {
  border-radius: 0;
  padding: 0;
  background: none;
  margin-bottom: 0;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item {
  font-size: 12px;
  font-weight: 500;
}
.breadcrumb-wrapper .breadcrumb .breadcrumb-item a {
  color: #a96d17;
}
.pagination-nav {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.pagination-nav li a {
  display: block;
  padding: 10px;
  color: #999;
  text-transform: uppercase;
}
.pagination-nav li.active a {
  font-weight: 700;
  color: #a96d17;
}
nav.section-header.margin-bottom {
  margin-bottom: 70px;
}
nav.section-header .header-top-wrapper {
  background: #1a3c5e;
  padding: 13px 0;
}
nav.section-header .header-top-wrapper .header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav.section-header .header-top-wrapper .header-top p {
  font-size: 13px;
  letter-spacing: -0.5px;
  color: #fff;
}
nav.section-header .header-top-wrapper .header-top ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-top-wrapper .header-top ul li a {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
nav.section-header .header-top-wrapper .header-top ul li a svg {
  height: 15px;
  width: 15px;
  margin-left: 6px;
  fill: #a96d17;
}
nav.section-header .header-top-wrapper .header-top ul li a:hover {
  color: #a96d17;
}
nav.section-header .header-top-wrapper .header-top ul li:not(:last-child) {
  margin-right: 20px;
}
nav.section-header .header-top-wrapper .header-top .nav-button {
  padding: 10px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  outline: none;
  display: none;
}
nav.section-header .header-top-wrapper .header-top .nav-button .button-bar {
  height: 2px;
  width: 24px;
  background: #fff;
  border-radius: 10px;
  transition: all 0.4s;
}
nav.section-header .header-top-wrapper .header-top .nav-button .button-bar:nth-child(2) {
  margin-top: 4px;
  width: 27px;
}
nav.section-header .header-top-wrapper .header-top .nav-button .button-bar:nth-child(3) {
  margin-top: 4px;
  width: 30px;
}
nav.section-header .header-top-wrapper .header-top .nav-button:hover .button-bar,
nav.section-header .header-top-wrapper .header-top .nav-button:focus .button-bar {
  width: 30px !important;
}
nav.section-header .header-management {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
nav.section-header .header-management .management-logo img {
  height: 45px;
}
nav.section-header .header-management .management-service {
  display: flex;
  align-items: center;
}
nav.section-header .header-management .management-service .service-contact .contact-inner {
  display: flex;
  align-items: center;
}
nav.section-header .header-management .management-service .service-contact .contact-inner svg {
  height: 20px;
  width: 20px;
  fill: #a96d17;
}
nav.section-header .header-management .management-service .service-contact .contact-inner .divider {
  height: 15px;
  width: 1px;
  background: #bbb;
}
nav.section-header .header-management .management-service .service-contact .contact-inner a {
  margin: 0 15px;
  font-size: 16px;
  font-weight: 900;
  color: #1a3c5e;
  transition: all 0.4s;
}
nav.section-header .header-management .management-service .service-contact .contact-inner a:hover {
  color: #a96d17;
}
nav.section-header .header-management .management-service .service-contact p {
  margin: 5px 15px 0 0;
  color: #777;
  font-weight: 400;
  font-size: 12px;
  text-align: right;
}
nav.section-header .header-management .management-service ul {
  display: flex;
  align-items: center;
}
nav.section-header .header-management .management-service ul li {
  height: 90px;
  width: 90px;
  border-left: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav.section-header .header-management .management-service ul li a {
  position: relative;
  text-align: center;
}
nav.section-header .header-management .management-service ul li a svg {
  height: 25px;
  width: 25px;
  fill: #a96d17;
}
nav.section-header .header-management .management-service ul li a p {
  margin-top: 5px;
  font-size: 10px;
  font-weight: 900;
  text-transform: uppercase;
  color: #1a3c5e;
  transition: all 0.4s;
}
nav.section-header .header-management .management-service ul li a:hover p {
  color: #a96d17;
}
nav.section-header .header-management .management-service ul li:last-child {
  border-right: 1px solid #eee;
}
nav.section-header .header-flexbox-wrapper {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
nav.section-header .header-flexbox-wrapper .header-flexbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:before,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close .container:after {
  content: none;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
  display: flex;
  align-items: center;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
  margin-right: 25px;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
  color: #1a3c5e;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
  display: block;
  padding: 18px 0;
  transition: all 0.4s;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a:hover,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a.active {
  color: #a96d17;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li:last-child {
  margin-right: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine {
  height: 55px;
  position: relative;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .button-search {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%;
  width: 90px;
  box-sizing: border-box;
  background: none;
  border: none;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  cursor: pointer;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .button-search svg {
  height: 25px;
  width: 25px;
  fill: #a96d17;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .button-search:hover,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .button-search.active {
  background: #a96d17;
  border-color: #a96d17;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .button-search:hover svg,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .button-search.active svg {
  fill: #fff;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper {
  right: 90px;
  top: 0;
  height: 100%;
  padding: 10px 10px 10px 80px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 12%, #ffffff 100%);
  position: absolute;
  opacity: 0;
  visibility: hidden;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper .button-close {
  position: absolute;
  padding: 0;
  height: 35px;
  width: 35px;
  box-sizing: border-box;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 111;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper .button-close svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 15px;
  width: 15px;
  fill: #a96d17;
  transform: translate(-50%, -50%);
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper .button-close:hover {
  background: #a96d17;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper .button-close:hover svg {
  fill: #fff;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper input {
  height: 100%;
  width: 450px;
  background: #eee;
  padding: 5px 10px 5px 40px;
  border: 1px solid transparent;
  font-size: 14px;
  color: #666;
  outline: none;
  opacity: 0;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper input:focus,
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper input.active {
  border: 1px solid #a96d17;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper.active {
  visibility: visible;
  opacity: 1;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine .input-wrapper.active input {
  opacity: 1;
}
nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine.mobile {
  display: none;
}
section.section-welcome .welcome-inner {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
section.section-welcome .welcome-inner h1 {
  font-weight: 300;
  color: #fff;
  font-size: 60.5px;
  text-align: center;
}
section.section-welcome .welcome-inner h1 strong {
  font-weight: 700;
  font-size: 56.5px;
}
section.section-welcome .welcome-inner .buttons-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
}
section.section-welcome .welcome-inner .buttons-wrapper .btn:not(:last-child) {
  margin-right: 30px;
}
section.section-latest {
  padding-top: 75px;
}
section.section-latest .nav-tabs {
  margin-top: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
section.section-latest .nav-tabs .nav-item {
  padding: 10px;
  margin: 0;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  color: #1a3c5e;
  transition: all 0.4s;
}
section.section-latest .nav-tabs .nav-item.active {
  color: #a96d17;
}
section.section-latest .tab-content .tab-pane .products-flexbox {
  margin-top: 10px;
}
section.section-latest .tab-content .tab-pane .products-flexbox .product-service {
  width: 20%;
}
section.section-latest .tab-content .tab-pane .products-flexbox .product-service:nth-child(5),
section.section-latest .tab-content .tab-pane .products-flexbox .product-service:nth-child(10) {
  border-right: none;
}
section.section-cta {
  padding-top: 70px;
}
section.section-cta .cta-flexbox {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
section.section-cta .cta-flexbox .flexbox-service {
  position: relative;
}
section.section-cta .cta-flexbox .flexbox-service .service-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #1a3c5e;
  opacity: 0.75;
}
section.section-cta .cta-flexbox .flexbox-service .service-inner {
  position: relative;
  padding: 50px;
  text-align: center;
}
section.section-cta .cta-flexbox .flexbox-service .service-inner .inner-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}
section.section-cta .cta-flexbox .flexbox-service .service-inner .inner-heading svg {
  height: 40px;
  width: 40px;
  fill: #a96d17;
  margin-right: 10px;
}
section.section-cta .cta-flexbox .flexbox-service .service-inner .inner-heading p {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}
section.section-cta .cta-flexbox .flexbox-service .service-inner .inner-description {
  margin-top: 20px;
}
section.section-cta .cta-flexbox .flexbox-service .service-inner .inner-description p {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}
section.section-cta .cta-flexbox .flexbox-service:not(:last-child) {
  border-right: 1px solid #86a6c6;
}
section.kategoria-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar {
  width: 20%;
  padding: 32px 20px 20px 20px;
  background: #1a3c5e;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading {
  margin-bottom: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading p {
  font-size: 18px;
  text-transform: uppercase;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
  outline: none;
  padding: 10px;
  width: 100%;
  border: none;
  font-size: 11px;
  text-transform: uppercase;
  background: #a96d17;
  color: #fff;
  transition: all 0.3s;
  display: none;
  cursor: pointer;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button:hover {
  background: #a96d17;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
  position: absolute;
  display: none;
  width: 100%;
  top: 20px;
  left: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container {
  display: flex;
  justify-content: flex-end;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:before,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close .container:after {
  content: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button {
  outline: none;
  border: none;
  background: none;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar {
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  background: #222;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(1) {
  transform: rotate(45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close button .button-bar:nth-child(2) {
  transform: rotate(-45deg);
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li {
  list-style: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
  color: #fff;
  opacity: 0.8;
  padding: 5px 0;
  font-size: 16px;
  transition: all 0.4s;
  display: block;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a:hover {
  color: #a96d17;
  padding-left: 5px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
  padding-left: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li a {
  padding: 5px 0;
  display: block;
  font-size: 14px;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li.active > a {
  opacity: 1;
  font-weight: 700;
  color: #a96d17;
  padding-left: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li:last-child {
  margin-bottom: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav {
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .heading:not(:first-of-type) {
  margin-top: 50px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .checkbox-wrapper .radio-box {
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type=number] {
  border: none;
  margin: 0 5px;
  outline: none;
  text-align: center;
  background: #1a3c5e;
  color: #fff;
  font-size: 14px;
  height: 30px;
  width: 100%;
  -moz-appearance: textfield;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type=number]::-webkit-outer-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type=number]:invalid,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper input[type=number]:out-of-range {
  border: 1px solid #ff6347;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper span {
  display: block;
  min-width: 12px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider .slider-wrapper p {
  font-size: 13px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider svg,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range] {
  position: absolute;
  left: 0;
  bottom: -40px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range] {
  -webkit-appearance: none;
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]:focus {
  outline: none;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]:focus::-webkit-slider-runnable-track {
  background: #cba42d;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]:focus::-ms-fill-lower {
  background: #cba42d;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]:focus::-ms-fill-upper {
  background: #cba42d;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
  animate: 0.2s;
  background: #cba42d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-webkit-slider-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #cba42d;
  height: 14px;
  width: 14px;
  border-radius: 25px;
  background: #ffeaa1;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: #cba42d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-moz-range-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #cba42d;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffeaa1;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-ms-fill-lower,
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-ms-fill-upper {
  background: #cba42d;
  border-radius: 1px;
  box-shadow: none;
  border: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .range-slider input[type=range]::-ms-thumb {
  z-index: 2;
  position: relative;
  box-shadow: 0px 0px 0px #000;
  border: 1px solid #cba42d;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #ffeaa1;
  cursor: pointer;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper {
  margin-top: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-clear {
  color: #fff;
  border: none;
  font-size: 13px;
  background: none;
  cursor: pointer;
  padding: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .filter-nav .buttons-wrapper .btn-filter {
  padding: 0;
  border: none;
  background: #a96d17;
  font-size: 13px;
  border-radius: 0;
  color: #fff;
  padding: 3px 10px;
  cursor: pointer;
  transition: all 0.3s;
}
section.kategoria-section-content .content-flexbox .flexbox-body {
  width: 75%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px 10px 10px 20px;
  background: #f5f5f5;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
  font-size: 13px;
  color: #777;
  position: relative;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper {
  display: flex;
  align-items: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
  margin-right: 30px;
  display: flex;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button {
  margin-right: 7px;
  padding: 0;
  border: none;
  background: #fff;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a img,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button img {
  width: 15px;
  opacity: 0.25;
  transition: all 0.4s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a:last-child,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button:last-child {
  margin-right: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a:hover img,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button:hover img {
  opacity: 1;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a.active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button.active {
  border: 1px solid #a96d17;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid a.active img,
section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid button.active img {
  opacity: 1;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading {
  margin-top: 60px;
  text-align: center;
}
section.kategoria-section-content .content-flexbox .flexbox-body .heading p {
  font-size: 18px;
  text-transform: uppercase;
  color: #1a3c5e;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta {
  margin-top: 40px;
  display: block;
  position: relative;
  transition: border-radius 0.4s;
  overflow: hidden;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta img {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  padding: 30px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner p {
  font-weight: 300;
  font-size: 24px;
  color: #fff;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta .cta-inner p strong {
  font-size: 31px;
  font-weight: 700;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-cta:hover {
  border-radius: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .btn-fixed {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 222;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;
  box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.3);
}
section.kategoria-section-content .content-flexbox .flexbox-body .btn-fixed.active {
  opacity: 1;
  visibility: visible;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
  width: 38%;
  display: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery img {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSPrev {
  width: 25px;
  height: 25px;
  background: url('../library/lightslider-master/dist/img/prev.svg') center;
  background-size: cover;
  margin-top: 0px;
  transform: translateY(-50%);
  left: 1px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSAction .lSNext {
  width: 25px;
  height: 25px;
  background: url('../library/lightslider-master/dist/img/prev.svg') center;
  background-size: cover;
  margin-top: 0px;
  transform: translateY(-50%) rotate(180deg);
  right: 1px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery {
  margin-top: 23px !important;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li {
  border: none;
  opacity: 0.6;
  transition: all 0.3s;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li.active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery .lSGallery li:hover {
  opacity: 1;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
  width: 100%;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info h2 {
  font-size: 36px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .code {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .code strong {
  font-weight: 700;
  color: #a96d17;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .description p {
  font-size: 14px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features > *:not(:first-child) {
  margin-top: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features p {
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features p strong {
  font-weight: 700;
  color: #999;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox label {
  font-size: 16px;
  font-weight: 300;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper {
  position: relative;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper select {
  cursor: pointer;
  padding: 10px 100px 10px 10px;
  border: none;
  border: 1px solid #eee;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  display: block;
  color: #666;
  width: 100%;
  height: auto;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .features .select-wrapper-flexbox .select-wrapper:after {
  cursor: pointer;
  content: '';
  height: 10px;
  width: 10px;
  background: url('../img/pictures/down-chevron.svg') center;
  background-size: cover;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price {
  margin-top: 20px;
  padding: 23px 20px 20px 20px;
  background: #f5f5f5;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-netto {
  color: #a96d17;
  font-size: 40px;
  font-weight: 500;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-netto span {
  font-weight: 400;
  color: #999;
  font-size: 12px;
  line-height: 15px;
  padding-left: 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-brutto {
  margin-top: 10px;
  font-weight: 400;
  color: #999;
  font-size: 18px;
  line-height: 18px;
  padding-left: 3px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper {
  margin-top: 20px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
  display: flex;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number {
  width: 100%;
  padding: 0 12px;
  text-align: center;
  outline: none;
  background: none;
  border: none;
  border: 1px solid #999;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  height: 35px;
  user-select: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment {
  min-width: 35px;
  background: #a96d17;
  color: #fff;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.4s;
  cursor: pointer;
  border: 1px solid #999;
  outline: none;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-decrement:active,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner .input-number-increment:active {
  background: #1a3c5e;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type=number]::-webkit-inner-spin-button,
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type=number]::-webkit-outer-spin-button {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner input[type=number] {
  -moz-appearance: textfield;
  margin: 0 10px;
}
section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .btn {
  margin-top: 20px;
  width: 100%;
}
section.static-page-section-content {
  padding: 0 0 70px 0;
}
section.static-page-section-content .content-heading {
  text-align: center;
}
section.static-page-section-content .content-heading h2 {
  font-size: 36px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}
section.static-page-section-content .content-inner {
  margin-top: 40px;
}
section.static-page-section-content .content-inner > *:not(:first-child) {
  margin-top: 20px;
}
section.static-page-section-content .content-inner p,
section.static-page-section-content .content-inner li {
  font-size: 15px;
  line-height: 26px;
  color: #444;
}
section.static-page-section-content .content-inner ul,
section.static-page-section-content .content-inner ol {
  padding-left: 17px;
}
section.kontakt-section-content {
  padding: 30px 0;
  position: relative;
}
section.kontakt-section-content .content-flexbox {
  display: flex;
  justify-content: space-between;
}
section.kontakt-section-content .content-flexbox .flexbox-heading {
  width: 35%;
}
section.kontakt-section-content .content-flexbox .flexbox-heading h2 {
  color: #444;
  font-size: 22px;
  font-weight: 400;
}
section.kontakt-section-content .content-flexbox .flexbox-heading h3 {
  margin-top: 8px;
  color: #a96d17;
  font-size: 45px;
  font-weight: 800;
}
section.kontakt-section-content .content-flexbox .flexbox-heading address {
  margin-top: 20px;
  color: #666;
  font-size: 18px;
  font-weight: 400;
}
section.kontakt-section-content .content-flexbox .flexbox-heading address small {
  margin-top: 20px;
  display: block;
  color: #999;
  margin-bottom: 7px;
}
section.kontakt-section-content .content-flexbox .flexbox-heading address a {
  font-weight: 700;
  color: #a96d17;
}
section.kontakt-section-content .content-flexbox .flexbox-iframe {
  width: 57%;
  height: 400px;
  position: relative;
}
section.kontakt-section-content .content-flexbox .flexbox-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border: none;
}
section.section-footer {
  background: #1a3c5e;
  position: relative;
}
section.section-footer .footer-partners {
  background: #fff;
  padding: 50px 0 40px 0;
}
section.section-footer .footer-partners .owl-carousel .owl-controls {
  display: none;
}
section.section-footer .footer-flexbox {
  display: flex;
  justify-content: space-between;
  position: relative;
}
section.section-footer .footer-flexbox .flexbox-service {
  padding: 50px 0;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-logo img {
  height: 45px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description {
  margin-top: 20px;
}
section.section-footer .footer-flexbox .flexbox-service.service-about .service-description p {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li {
  margin-top: 5px;
}
section.section-footer .footer-flexbox .flexbox-service ul li.heading {
  margin-top: 0;
  padding-bottom: 10px;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}
section.section-footer .footer-flexbox .flexbox-service ul li a {
  font-size: 13px;
  font-weight: 300;
  color: #fff;
  transition: all 0.3s;
}
section.section-footer .footer-flexbox .flexbox-service ul li a b {
  font-size: 16px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service ul li a:hover {
  color: #a96d17;
}
section.section-footer .footer-flexbox .flexbox-service ul li p {
  font-size: 12px;
  color: #444;
}
section.section-footer .footer-flexbox .flexbox-service ul li p b {
  font-weight: 400;
  font-size: 14px;
}
section.section-footer .footer-flexbox .flexbox-service ul li p span {
  font-size: 10px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li {
  margin-right: 20px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li a img {
  height: 25px;
}
section.section-footer .footer-flexbox .flexbox-service .list-social li:last-child {
  margin-right: 0;
}
section.section-footer .footer-flexbox .divider {
  width: 1px;
  background: rgba(255, 255, 255, 0.2);
}
section.section-footer .footer-alert-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 40px 0;
}
section.section-footer .footer-alert-wrapper .alert {
  position: relative;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0;
  background: none;
}
section.section-footer .footer-alert-wrapper .alert p {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
}
section.section-footer .footer-alert-wrapper .alert p a {
  color: #a96d17;
  cursor: pointer;
}
section.section-footer .footer-credits-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: 25px 0;
}
section.section-footer .footer-credits-wrapper .footer-credits {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
section.section-footer .footer-credits-wrapper .footer-credits p {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
}
section.section-footer .footer-credits-wrapper .footer-credits p a {
  color: #a96d17;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment {
  display: flex;
  align-items: center;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment p {
  margin-right: 15px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img {
  margin-right: 10px;
  height: 19px;
}
section.section-footer .footer-credits-wrapper .footer-credits .footer-payment img:last-child {
  margin-right: 0;
}
@media (min-width: 1199.98px) {
  .container {
    max-width: 1260px;
  }
}
@media (max-width: 991.98px) {
  .select-wrapper select {
    padding: 5px 60px 5px 10px;
  }
  .products-flexbox.grid-flex .product-service {
    width: 32%;
    padding: 0 10px;
  }
  nav.section-header .header-management {
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
  }
  nav.section-header .header-management .management-logo {
    order: 1;
  }
  nav.section-header .header-management .management-search-engine {
    margin-top: 25px;
    order: 3;
    width: 100%;
  }
  nav.section-header .header-management .management-service {
    order: 2;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    text-align: center;
    padding: 0 10px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    padding: 15px 0;
    font-size: 11px;
  }
  section.section-latest .tab-content .tab-pane .products-flexbox .product-service {
    width: 32%;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-inner {
    padding: 20px;
  }
  section.section-cta .cta-flexbox .flexbox-service .service-inner .inner-heading p {
    font-size: 14px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar {
    width: 25%;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body {
    width: 72%;
  }
  section.section-footer .footer-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.section-footer .footer-flexbox .flexbox-service {
    text-align: center;
    padding: 15px 0;
  }
  section.section-footer .footer-partners {
    padding: 30px 0;
  }
  section.section-footer .footer-alert-wrapper .alert {
    text-align: center;
  }
  section.section-footer .footer-credits-wrapper .footer-credits {
    flex-direction: column;
    text-align: center;
  }
  section.section-footer .footer-credits-wrapper .footer-credits p {
    font-size: 12px;
  }
}
@media (max-width: 767.98px) {
  nav.section-header .header-management {
    flex-direction: column;
  }
  nav.section-header .header-management .management-service ul li:first-of-type {
    display: none;
  }
  nav.section-header .header-top-wrapper .header-top .nav-button {
    display: flex;
    margin-right: -10px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox {
    justify-content: flex-end;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine.mobile {
    display: block;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine.mobile .button-search {
    border: none;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine.mobile .input-wrapper input {
    width: 300px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine.desktop {
    display: none;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .nav-button-close {
    display: block;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100%;
    padding: 50px;
    margin-right: 0;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .management-logo.hide {
    display: none;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav {
    flex-direction: column;
    background: transparent;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li {
    margin-right: 0;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper .flexbox-nav-inner .flexbox-nav li a {
    font-size: 16px;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
  }
  section.section-welcome .welcome-inner h1 {
    font-size: 55px;
  }
  section.section-latest .tab-content .tab-pane .products-flexbox .product-service {
    width: 32%;
    padding: 0 20px;
  }
  section.section-cta .cta-flexbox {
    grid-template-columns: 1fr;
  }
  .products-flexbox.kategoria .product-service {
    width: 30%;
  }
  section.kategoria-section-content {
    padding: 0 0 50px 0;
  }
  section.kategoria-section-content .content-flexbox {
    flex-direction: column;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar {
    width: 100%;
    padding: 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .heading {
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    background: #fff;
    z-index: 555;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .nav-button-close {
    display: block;
    margin-left: -15px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    padding: 50px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li {
    text-align: center;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li a {
    font-size: 17px;
    padding: 5px 0;
    color: #1a3c5e;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul {
    padding-left: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper .category-nav-inner .category-nav li ul li a {
    color: #a96d17;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .category-nav-wrapper.active {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-sidebar .nav-button {
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body {
    width: 100%;
    margin-top: 20px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product {
    flex-direction: column;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-gallery {
    width: 100%;
    display: block;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info {
    width: 100%;
    padding-top: 30px;
    text-align: center;
  }
  section.kontakt-section-content .content-flexbox {
    flex-direction: column;
    align-items: center;
  }
  section.kontakt-section-content .content-flexbox .flexbox-heading {
    width: 100%;
    text-align: center;
  }
  section.kontakt-section-content .content-flexbox .flexbox-iframe {
    margin-top: 30px;
    width: 90%;
  }
}
@media (max-width: 575.98px) {
  nav.section-header .header-management .management-service .service-contact p {
    display: none;
  }
  nav.section-header .header-management .management-service .service-contact .contact-inner {
    flex-direction: column;
  }
  nav.section-header .header-management .management-service .service-contact .contact-inner svg,
  nav.section-header .header-management .management-service .service-contact .contact-inner .divider {
    display: none;
  }
  nav.section-header .header-management .management-service .service-contact .contact-inner a {
    margin: 5px 10px 0 0;
    font-size: 13px;
  }
  nav.section-header .header-top-wrapper .header-top ul li:nth-of-type(3) {
    display: none;
  }
  section.section-welcome .welcome-inner h1 {
    font-size: 46px;
    line-height: 50px;
  }
  section.section-welcome .welcome-inner h1 strong {
    font-size: 40px;
  }
  section.section-welcome .welcome-inner .buttons-wrapper {
    flex-direction: column;
  }
  section.section-welcome .welcome-inner .buttons-wrapper .btn:not(:last-child) {
    margin: 0;
  }
  section.section-welcome .welcome-inner .buttons-wrapper .btn-empty {
    margin-top: 15px;
  }
  .products-flexbox .product-service {
    border-right: none;
    padding: 0 10px;
  }
  .products-flexbox .product-service .service-quantity button {
    height: 35px;
    width: 55px;
  }
  .products-flexbox .product-service .service-quantity input {
    height: 35px;
    width: 60px;
    padding-left: 0;
  }
  .products-flexbox.grid-flex .service-quantity {
    flex-direction: column;
    align-items: flex-start;
  }
  .products-flexbox.grid-flex .service-quantity .quantity-inner {
    margin-left: 0;
  }
  .products-flexbox.grid-flex .service-quantity button {
    height: 35px;
    width: 55px;
  }
  .products-flexbox.grid-flex .service-quantity input {
    height: 35px;
    width: 60px;
  }
  .products-flexbox.kategoria .product-service {
    width: 47%;
  }
  .products-flexbox.kategoria .product-service:last-of-type {
    display: none;
  }
  .products-flexbox.grid-list .product-service {
    text-align: left;
    padding: 20px 0;
  }
  .products-flexbox.grid-list .product-service:not(:first-child) {
    padding: 10px 0;
  }
  .products-flexbox.grid-list .product-service header {
    min-width: 22%;
  }
  .products-flexbox.grid-list .product-service .footer {
    width: 50%;
  }
  .products-flexbox.grid-list .product-service .name {
    font-size: 13px;
  }
  .products-flexbox.grid-list .product-service .price {
    display: flex;
    flex-direction: column;
    line-height: 20px;
  }
  .products-flexbox.grid-list .product-service .price-small {
    top: 70px;
  }
  .products-flexbox.grid-list .product-service .service-quantity {
    top: 125px;
  }
  .products-flexbox.grid-list .product-service .service-quantity input {
    height: 30px;
  }
  .products-flexbox.grid-list .product-service .service-quantity button {
    width: 50px;
    height: 30px;
  }
  .products-flexbox.grid-list .product-service .footer-quantity {
    margin-top: 8px;
  }
  section.section-latest .nav-tabs .nav-item {
    font-size: 16px;
  }
  section.section-latest .tab-content .tab-pane .products-flexbox .product-service {
    width: 50%;
    padding: 0 17px;
  }
  section.section-cta {
    padding-top: 40px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .quantity-wrapper .wrapper-inner {
    justify-content: center;
    padding-top: 4px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-product .product-info .price .price-netto {
    justify-content: center;
  }
}
@media (max-width: 400px) {
  .breadcrumb-wrapper {
    display: none;
  }
  .products-flexbox.wyszukiwanie .product-service {
    width: 47%;
  }
  nav.section-header .header-flexbox-wrapper .header-flexbox .flexbox-search-engine.mobile .input-wrapper input {
    width: 230px;
  }
  section.static-page-section-content {
    padding: 50px 0 70px 0;
  }
  section.static-page-section-content .content-inner {
    text-align: center;
  }
  section.static-page-section-content .content-inner ul {
    text-align: left;
  }
  section.kategoria-section-content {
    padding: 20px 0 50px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading {
    padding: 10px 10px 10px 0;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .heading-text {
    font-size: 11.5px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .heading-grid {
    margin-right: 10px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .select-wrapper {
    width: 130px;
  }
  section.kategoria-section-content .content-flexbox .flexbox-body .body-heading .inner-wrapper .select-wrapper select {
    padding: 5px 17px 5px 10px;
  }
  section.kontakt-section-content .content-flexbox .flexbox-iframe {
    height: 250px;
  }
}
